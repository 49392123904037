/* eslint-disable global-require */
/* eslint-disable import/first */
require('core-js/features/object/define-property');
require('core-js/features/array/is-array');
require('core-js/features/array/for-each');
require('core-js/features/string/trim');
require('core-js/features/object/keys');
require('./vendors/json2');

require('../scss/style.scss');

module.exports = {
  init: require('./init').default
};

// import init from './init';

// init({ type: 'inner' });
