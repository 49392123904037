export default class HTTP {
  constructor(option) {
    this.url = option.url;
    this.data = option.data;
    this.async = !(option.async || false);
    this.success = option.success;
    this.fail = option.fail;
    this.dataType = option.dataType;
    this.type = option.type;
    this.init();
  }

  init() {
    switch (this.type) {
      case 'get':
        this.get();
        break;
      default:
        this.get();
    }
  }

  delData() {
    let data = '';
    if (this.data) {
      Object.keys(this.data).forEach(key => {
        data += `${key}=${this.data[key]}&`;
      });
    } else {
      return '';
    }
    return data.substring(0, data.length - 1);
  }

  get() {
    const This = this;
    let http = null;

    if (window.XMLHttpRequest) {
      http = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
      // eslint-disable-next-line no-undef
      http = new ActiveXObject('Microsoft.XMLHTTP');
    }

    http.open('get', `${this.url}?${this.delData()}`);
    http.setRequestHeader('Cache-Control', 'no-cache');
    http.setRequestHeader('If-Modified-Since', '0');
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          if (typeof This.success === 'function') {
            This.success(http.responseText);
          }
        }
      } else if (typeof This.fail === 'function') {
        This.fail(http.responseText);
      }
    };
    http.send();
  }
}
