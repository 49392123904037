export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/;domain=4399dmw.com;SameSite=None; Secure`;
}

export function getCookie(name) {
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  const arr = document.cookie.match(reg);
  if (arr) {
    return unescape(arr[2]);
  }
  return null;
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
}

export function redirectTo(url) {
  window.location.href = url;
}

export function addEvent(obj, type, fn) {
  if (!obj) {
    return;
  }

  if (obj.addEventListener) {
    obj.addEventListener(type, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent(`on${type}`, fn);
  }
}

export function collect(title, url) {
  const collectTitle = title || '4399动漫 - www.4399dmw.com';
  const collectUrl = url || 'http://www.4399dmw.com';

  if (window.external && 'AddFavorite' in window.external) {
    window.external.AddFavorite(collectUrl, collectTitle);
  } else {
    // eslint-disable-next-line no-alert
    alert('您的浏览器不支持该功能,请使用Ctrl+D收藏本页');
  }
}
