import {
  renderUserInfo,
  renderUserAccount,
  renderPublicAccount
} from './render';
import { getUserAccountInfo, getTopNavAd } from './service';
import eventHandle from './event';

export default function init(options) {
  const $nav = document.getElementById('j-anime-nav');
  // const userInfo = getUserInfo();
  getUserAccountInfo(res => {
    const userInfo = JSON.parse(res);
    if (!options.type) {
      throw new TypeError(`options.type should be 'index' or 'inner'`);
    }

    if (options.type === 'index') {
      $nav.className = 'anime-nav anime-nav--index';
    } else {
      $nav.className = 'anime-nav';
    }

    if (userInfo.username) {
      renderUserInfo(userInfo);
    } else {
      renderUserInfo(null);
    }

    if (window.topbar_html_url) {
      getTopNavAd(window.topbar_html_url, data => {
        renderPublicAccount(data);
      });
    }

    // if (options.publicHTML) {
    //   renderPublicAccount(options.publicHTML);
    // }

    eventHandle();

    if (userInfo.username) {
      const { dmcoin, ticket } = JSON.parse(res);
      renderUserAccount(dmcoin, ticket);
    }
    return userInfo;
  });
}

init({ type: 'inner' });
