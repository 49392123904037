/**
 * generate inline userinfo html string
 * @param {object} userInfo userinfo
 * @return {string} html string
 */
function genInlineUserInfoHTMLString(userInfo) {
  const { uid, username } = userInfo;
  return `
    <img src="//a.img4399.com/${uid}/small" alt="头像" class="anime-nav__avatar">
    <span class="anime-nav__nickname">${username}</span>
  `.trim();
}

/**
 * generate inline login and register html string
 * @return {string} html string
 */
function genLoginAndRegisterHTMLString() {
  return `
    <div class="anime-nav__account" id="j-anime-nav-account">
      <a href="/user-login.php" target="_self" rel="noopener" title="等你好久啦！">登录</a>
      <a href="/user-reg.php" target="_self" rel="noopener" title="缔结契约吧！">注册</a>
    </div>
  `.trim();
}

/**
 * generate user profile html string
 * @param {object} userInfo userinfo
 * @return {string} html string
 */
function genProfileHTMLString(userInfo) {
  const { uid, username } = userInfo;
  return `
    <div class="anime-nav__profile" id="j-anime-nav-profile">
      <a href="//u.4399.com/profile/" target="_blank" class="anime-nav__profile-info">
        <img src="//a.img4399.com/${uid}/middle" class="anime-nav__profile-avatar" alt="头像">
        <span class="anime-nav__profile-nickname">${username}</span>
      </a>
      <div class="anime-nav__profile-wallet">
        <a href="/user-account.php" class="anime-nav__profile-money" id="j-anime-nav-coin">
          <span>宅币</span><em>0</em>
        </a>
        <a href="/user-account.php" class="anime-nav__profile-money" id="j-anime-nav-ticket">
          <span>宅券</span><em>0</em>
        </a>
      </div>
      <div class="anime-nav__profile-entrys">
        <a href="/user-fav.php" class="anime-nav__profile-entry anime-nav__profile-entry--collect"><i class="anime-nav__entry-icon"></i>我的漫画</a>
        <a href="/user-history.php" class="anime-nav__profile-entry anime-nav__profile-entry--record"><i class="anime-nav__entry-icon"></i>观看记录</a>
        <a href="/user-account.php" class="anime-nav__profile-entry anime-nav__profile-entry--account"><i class="anime-nav__entry-icon"></i>账户中心</a>
      </div>
      <a href="javascript:void(0);" onclick="return false" class="anime-nav__profile-exit" id="j-anime-nav-exit">
        <i></i>
        <span>退出</span>
      </a>
    </div>
  `.trim();
}

/**
 * update user account info
 * @param {number} coin 宅币
 * @param {number} ticket 宅券
 */
export function renderUserAccount(coin, ticket) {
  const $coin = document.getElementById('j-anime-nav-coin');
  const $ticket = document.getElementById('j-anime-nav-ticket');

  $coin.innerHTML = `<span>宅币</span><em>${coin}</em>`;
  $ticket.innerHTML = `<span>宅券</span><em>${ticket}</em>`;
}

/**
 * render wechat public account and recommend
 * @param html
 */
export function renderPublicAccount(html) {
  const $public = document.getElementById('j-anime-nav-public');
  $public.innerHTML = html;
}

/**
 * render userinfo
 * @param {object} userInfo userinfo
 */
export function renderUserInfo(userInfo) {
  const $userContainer = document.getElementById('j-anime-nav-user');
  if (userInfo) {
    $userContainer.innerHTML = `
      <div class="anime-nav__userinfo">
        ${genInlineUserInfoHTMLString(userInfo)}
        ${genProfileHTMLString(userInfo)}
      </div>
    `.trim();
  } else {
    $userContainer.innerHTML = genLoginAndRegisterHTMLString();
  }
}

/**
 * render html
 * @param {string} html adHtml
 */
export function renderTopNavAd(html) {
  const $navPublic = document.getElementById('j-anime-nav-public');
  if (html) {
    $navPublic.innerHTML = html.trim();
  }
}
