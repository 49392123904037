import { getCookie, deleteCookie,redirectTo } from './util';
import { renderUserInfo } from './render';
import HTTP from './ajax';

export function getUserAccountInfo(resolve, reject) {
  // eslint-disable-next-line no-new
  new HTTP({
    url: '/pcapi/user-currencydetail.php',
    type: 'get',
    data: {},
    success: resolve,
    fail: reject
  });
}

export function getTopNavAd(url, resolve, reject) {
  // eslint-disable-next-line no-new
  new HTTP({
    url,
    type: 'get',
    data: {},
    success: resolve,
    fail: reject
  });
}

export function getUserInfo() {
  const authCookie = getCookie('dmwauth');
  if (authCookie) {
    const [time, uid, username, usertype, expire, sig] = decodeURIComponent(
      authCookie
    ).split('|');
    return { time, uid, username, usertype, expire, sig };
  }
  return null;
}

export function logout() {
  deleteCookie('dmwauth');
  // TODO:个人中心登出要跳转登录页，普通页面不需要，后续做成统一模板需要做区分
  // redirectTo('/user-logout.php');
  // add login iframe
  /*const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = '/user-logout.php';
  document.body.appendChild(iframe);*/
  // remove inline userInfo and user profile
  //window.location.href = '/user-logout.php';
  new HTTP({
    url:"/user-logout.php",
    type: 'get',
    success: function(){
      var _refer =  encodeURIComponent(getCookie("loginbackurl"));
      redirectTo('https://u.4399.com/sso/dmw/signout.html?redirect='+_refer);
    }
  });
  renderUserInfo(null);
}
