import { logout } from './service';
import { addEvent, collect } from './util';

export default function eventHandle() {
  const $exit = document.getElementById('j-anime-nav-exit');
  const $collect = document.getElementById('j-anime-nav-collect');

  addEvent($exit, 'click', evt => {
    const event = evt || window.event;
    if (evt.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    logout();
  });

  addEvent($collect, 'click', evt => {
    const event = evt || window.event;
    if (evt.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    collect();
  });
}
